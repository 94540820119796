<template>
  <div class="wrap-edit">
    <div class="edit-header">
      <span>送达回执（{{ shouli ? "受理" : "发证" }}）</span>
      <div>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button type="primary" @click="print">打印</el-button>
      </div>
    </div>
    <div class="edit-container">
      <div class="forms">
        <h1 style="font-size: 25px;font-weight: 400;margin-bottom:50px ">送达回执</h1>
        <div style="margin-left: 100px">
          <p style="font-size: 14px">
            受送达人：<span>{{ form.ssdr }}</span>
          </p>
          <p style="font-size: 14px">
            项目名称：<span>{{ form.xmmc }}</span>
          </p>
          <p style="font-size: 14px">
            送达文书：{{ zsmc }}
          </p>
          <p v-if="!shouli" style="font-size: 14px">
            文书编号：<strong>
              <input type="text" v-model="form.wsbh" style="border:none; width:500px"  @change="onSubmit">
            </strong>
          </p>
        </div>
        <div style="display: flex;justify-content: space-between;margin-left: 100px;margin-top: 90px">
          <p style="font-size: 14px">
            收件人签字或盖章：
          </p>
          <div style=";margin-right: 100px;width: 160px;">
            <p style="font-size: 14px">
              接件人：<span>{{ form.jjr }}</span>
            </p>
            <p style="font-size: 14px;">
              送达人：<span>{{ form.ssdr }}</span>
            </p>
          </div>
        </div>
        <div style="display: flex;justify-content: space-between;margin-left: 100px">
          <p style="font-size: 14px">
            收件日期：
          </p>
          <div style="margin-right: 40px;justify-content: center;display: flex;align-items: center;">
            <div style="display:inline;">
              <p style="display:inline;font-size: 14px;width: 160px;">{{ shouli ? "接件" : "发证" }}日期：</p>
            </div>
            <div style="display:inline;">
              <el-date-picker style="width: 150px" v-model="form.jjrq" format="YYYY-MM-DD" value-format="YYYY-MM-DD" @change="onSubmit"></el-date-picker>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="wrap-edit" v-show="false">
    <div class="edit-header">
      <span>送达回执（{{ shouli ? "受理" : "发证" }}）</span>
      <el-button type="primary" @click="print">打印</el-button>
    </div>
    <div class="edit-container">
      <div id="forms" class="forms">
        <h1 style="font-size: 25px;font-weight: 400;margin-bottom:50px ">送达回执</h1>
        <div style="margin-left: 100px">
          <p style="font-size: 14px">
            受送达人：<span>{{ form.ssdr }}</span>
          </p>
          <p style="font-size: 14px">
            项目名称：<span>{{ form.xmmc }}</span>
          </p>
          <p style="font-size: 14px">
            送达文书：{{ zsmc }}
          </p>
          <p v-if="!shouli" style="font-size: 14px">
            文书编号：<strong>
              <input type="text" v-model="form.wsbh" style="border:none; width:500px">
            </strong>
          </p>
        </div>
        <div style="display: flex;justify-content: space-between;margin-left: 100px;margin-top: 90px">
          <p style="font-size: 14px">
            收件人签字或盖章：
          </p>
          <div style=";margin-right: 100px;width: 160px;">
            <p style="font-size: 14px">
              接件人：<span>{{ form.jjr }}</span>
            </p>
            <p style="font-size: 14px;">
              送达人：<span>{{ form.ssdr }}</span>
            </p>
          </div>
        </div>
        <div style="display: flex;justify-content: space-between;margin-left: 100px">
          <p style="font-size: 14px">
            收件日期：
          </p>
          <div style="margin-right: 40px;justify-content: center;display: flex;align-items: center;">
            <div style="display:inline;">
              <p style="display:inline;font-size: 14px;width: 160px;">{{ shouli ? "接件" : "发证" }}日期：<span style="margin-right: 80px;" >{{ getDate(form.jjrq)}}</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import print from "print-js";
import {ElMessage} from "element-plus";
import dayjs from "dayjs";
export default {
  name: 'fzsdhz',
  data() {
    return {
      shouli: false,
      zsmc: "",
      form: {
        ssdr: "",
        xmmc: "",
        wsbh: "",
        jjr: "",
        jjrq: new Date(),
      },
    };
  },
  methods: {
    getDate(date) {
      var a = date
      a= dayjs(date).format('YYYY-MM-DD')
      return a
    },
    onSubmit() {
      this.form.bcsj=this.form.jjrq;
      this.form.type = this.shouli ? '送达' : '发证';
      this.$ajax
        .post( this.$appConfig.apiUrls.ywxtApi + '/api/YW/AddorUpdateSDHZ',this.form)
        .then(function (response) {
          if (response.data.IsSuccess) {
            ElMessage({
              type: "success",
              message: "保存成功!",
            });
          } else {
            ElMessage({
              type: "info",
              message: "保存失败!",
            });
          }
        });
    },
    getSDHZ(Id){
      let type = this.shouli ? '送达' : '发证';
      let me = this;
      this.$ajax
          .get(this.$appConfig.apiUrls.ywxtApi + "/api/YW/GetSDHZ?BJID=" + Id+"&TYPE="+type)
          .then((response) => {
            if(response.data.IsSuccess){
              let res = response.data.Data;
              if(res){
                 me.form.wsbh = res.WSBH;
                 me.form.jjrq = res.BCSJ;
                 me.form.Id = res.Id;
              }else{
                me.form.jjrq = dayjs(new Date()).format('YYYY-MM-DD');
                me.getFZSDHZWH();
              }
            }else{
              console.log('error');
            }
         } )
    },
    getFZSDHZWH() {
      let me = this;
      this.$ajax
        .get(this.$appConfig.apiUrls.ywxtApi + "/api/YW/GetFZSDHZWH?bjid=" + this.$route.query.instanceId + "&flowid=" + this.$route.query.fid)
        .then((response) => {
          if (response.data.IsSuccess) {
            let res = response.data.Data;
            if (res) {
              me.form.wsbh = res;
            }
          } else {
            console.log('error');
          }
        })
    },
    async fetch(Id) {
      this.form.bjid = Id;
      try {
        await this.$ajax
          .get(this.$appConfig.apiUrls.ywxtApi + "/api/YW/Getlahz?Id=" + Id)
          .then((response) => {
            if (response.data.IsSuccess) {
              const data = response.data.Data;
              this.form.ssdr = data.JSDW;
              this.form.xmmc = data.XMMC;
              this.form.jjr = data.JJRXM;
              this.getSDHZ(Id);
            } else {
              throw response.data.ErrorMessage;
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    print() {
      this.isshow = false
      this.notshow = true

      print({
        printable: "forms",
        type: "html",
        scanStyles: false,
        css: "/static/print-style.css",
        documentTitle: "",
      });

    },
    async getzsmc() {
      let me = this
      await this.$ajax.get(this.$appConfig.apiUrls.ywxtApi + "/api/Dictionary/GetCardNameByFlowId?FlowId=" + me.$route.query.fid)
        .then((response) => {
          if (response.data.IsSuccess) {
            me.zsmc = me.shouli ? "慈溪市行政服务中心市自然资源和规划局窗口受理通知单" : response.data.Data
          }
        });
    }
  },
  mounted() {
    this.shouli = this.$route.query.state == "0" ? true : false;
    this.fetch(this.$route.query.instanceId);
    this.getzsmc()
  },
  watch: {
    $route() {
      this.shouli = this.$route.query.state == "0" ? true : false;
      this.form= {
        ssdr: "",
        xmmc: "",
        wsbh: "",
        jjr: "",
        jjrq: new Date(),
      }
      this.fetch(this.$route.query.instanceId);
      this.getzsmc()
    },
  },
};
</script>

<style scoped>
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  margin-bottom: 10px;
}

.edit-container {
  margin-top: 0px;
  background-color: #ffffff;
  border: 1px black solid;
  overflow: hidden;
  padding: 20px;
}

.right-align {
  text-align: right;
}

.form {
  padding: 20px;
  width: calc(100% - 42px);
  margin: auto;
  background-color: white;
  border: 1px black solid;
  overflow: hidden;
}

p {
  margin: 1em 0;
  text-align: justify;
}

.body {
  text-indent: 2em;
}

strong {
  text-decoration: underline;
}

h1 {
  text-align: center;
  font-size: 2em;
}
</style>
